<template>
  <v-container class="pa-0">
    <v-tabs-items v-model="tab">
      <v-tabs v-model="tab" grow dark :background-color="success_dark">
        <v-tabs-slider></v-tabs-slider>
        <v-tab key="tab_tables">
          <v-icon>mdi-table-furniture</v-icon>
          <small>Mesas</small>
        </v-tab>

        <v-tab key="tab_bill">
          {{ table_selected }}
          <span>( {{ this.totals.total.toLocaleString() }} )</span>
        </v-tab>
        <!-- 
        <v-tab  key="tab_menu">
          <small>Menú</small>
          <v-icon>mdi-silverware</v-icon>
        </v-tab> -->
      </v-tabs>
      <v-tab-item key="tab_tables">
        <v-tabs show-arrows>
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="area in areas" :key="area" :href="'#tab-' + area" @click="changeArea(area)">
            {{ area }}
          </v-tab>
        </v-tabs>
        <v-divider class="pa-2"></v-divider>
        <v-card flat>
          <v-card-title class="pa-0"> </v-card-title>
          <v-card-text class="pa-0">
            <v-spacer></v-spacer>
            <v-row justify="center" align="center">
              <v-col cols="auto" v-for="product in tbls" :key="product">
                <v-card width="160px" v-if="storeBills[area_selected + '-' + product].total === 0" class="mx-auto"
                  :color="success_color" @click="changeTable(area_selected + '-' + product)">
                  <v-card-title>
                    {{ area_selected + "-" + product }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>{{
                        "$ " +
                        storeBills[
                          area_selected + "-" + product
                        ].total.toLocaleString()
                    }}
                  </v-card-actions>
                </v-card>
                <v-card v-else width="160px" class="mx-auto" :color="warning_color"
                  @click="changeTable(area_selected + '-' + product)">
                  <v-card-title>
                    {{ area_selected + "-" + product }}
                  </v-card-title>
                  <v-card-actions>
                    {{ storeBills[area_selected + "-" + product].seat }}
                    <v-spacer></v-spacer>
                    {{
                        "$ " +
                        storeBills[
                          area_selected + "-" + product
                        ].total.toLocaleString()
                    }}
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="tab_bill">
        <v-data-table :headers="header" :items="cart" :items-per-page="-1" item-key="index" hide-default-footer
          sort-by="name" mobile-breakpoint="0" loading-text="Cargando ...">
          <template v-slot:top>
            <v-app-bar flat color="transparent">
              {{ table_selected }}
              <v-spacer></v-spacer>
              <v-btn x-small :color="success_dark" fab dark @click="dialog_menu = !dialog_menu">
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-app-bar>
          </template>
          <template v-slot:[`item.qty`]="{ item }">
            <v-avatar size="30" :color="colors[item.seat]" @click="editItem(item, true)">
              {{ item.qty }}
            </v-avatar>
          </template>
          <template v-slot:[`item.product_name`]="{ item }">
            <v-row @click="editItem(item, true)">
              <v-col cols="12" class="pb-0">
                <b class="text-uppercase">
                  {{ item.qty }} X {{ item.product_name }}
                </b>
              </v-col>
              <v-col cols="12" class="pt-1" v-if="item.note">
                {{ item.note }}
              </v-col>
            </v-row>
            <template v-if="item.product_options">
              <v-row>
                <v-col cols="12" v-for="(value, key) in item.product_options" :key="key" justify="center">
                  <v-data-table :headers="headerSelected" hide-default-header hide-default-footer :items-per-page="-1"
                    mobile-breakpoint="0" :items="
                      item.product_options[key].filter(function (rg) {
                        return rg.selected === true;
                      })
                    " item-key="product_name" :single-select="selecttype(item.product_options[key])" dense>
                  </v-data-table>
                </v-col>
              </v-row>
            </template>
          </template>
          <template v-slot:[`item.price`]="{ item }" class="text-right">
            {{
                (
                  item.qty *
                  (parseFloat(item.price) + parseFloat(item.value_options))
                ).toLocaleString()
            }}
            <v-spacer></v-spacer>
            <v-btn icon x-small @click="deleteItem(item)" v-if="item.active">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-icon v-else small color="#81D4FA">mdi-check-all</v-icon>
          </template>
        </v-data-table>
        <v-toolbar width="100%">
          <v-spacer />
          <v-btn :color="success_dark" dark v-if="totals.complete">
            <v-icon class="pr-3">mdi-cash-register</v-icon> Registrar Pago
          </v-btn>

          <v-btn :color="success_dark" dark v-if="!totals.complete" @click="saveDoc()">
            Enviar <v-icon small class="pl-2">mdi-send</v-icon>
          </v-btn>
        </v-toolbar>
      </v-tab-item>
    </v-tabs-items>

    <v-navigation-drawer v-model="dialog_menu" fixed right width="100%" :fullscreen="$vuetify.breakpoint.xsOnly" app>
      <v-app-bar fixed dark :color="success_dark">
        {{ table_selected }}
        <v-spacer></v-spacer>
        {{ this.totals.total.toLocaleString() }}
        <v-spacer></v-spacer>
        <v-btn x-small icon @click="dialog_menu = !dialog_menu">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-app-bar>
      <v-card flat class="pt-12">
        <v-card-title class="pa-0"> </v-card-title>
        <v-card-text>
          <div v-for="value in this.settings.categories_order" :key="value" justify="center">
            <h1 class="text-center text-h5 font-weight-black pt-6 pb-6" :id="'tab-' + value.replace(/\s/g, '')">
              {{ value }}
            </h1>

            <v-row fill-height align="center" justify="start">
              <v-col v-for="(child, index) in menu[value]" :key="index" cols="12" md="6" lg="4">
                <v-card>
                  <v-list-item three-line @click="editItem(child, false)" v-if="child.picture && settings.whitPhoto">
                    <v-list-item-avatar rounded size="80">
                      <v-img cover :src="
                        getImagePhoto(
                          child.account,
                          child.store,
                          child.picture
                        )
                      " />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-black text-uppercase" v-text="child.product_name" />
                      <v-list-item-subtitle v-html="child.description" />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>
                        <slot v-if="child.discount === 0">
                          {{ parseFloat(child.price).toLocaleString() }}
                        </slot>
                        <slot v-else>
                          <small class="text-decoration-line-through">{{
                              "$ " + parseFloat(child.price).toLocaleString()
                          }}</small><br />
                          {{
                              "$ " +
                              (
                                parseFloat(child.price) *
                                ((100 - child.discount) / 100)
                              ).toLocaleString()
                          }}
                        </slot>
                      </v-list-item-action-text>
                      <v-spacer></v-spacer>
                      <v-btn icon x-small>
                        <v-icon>mdi-cart-variant</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item three-line v-else @click="editItem(child, false)">
                    <v-list-item-content>
                      <v-list-item-title class="text-uppercase">
                        <b>{{ child.product_name }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle class="text--primary" v-html="child.description"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>{{
                          parseFloat(child.price).toLocaleString()
                      }}</v-list-item-action-text>
                      <v-spacer></v-spacer>
                      <v-btn icon x-small>
                        <v-icon>mdi-cart-variant</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <v-expansion-panels accordion flat>
            <v-expansion-panel v-for="(item, i) in menu" :key="i">
              <v-expansion-panel-header>
                <v-card
                  color="#ffffff"
                  elevation="10"
                  v-if="getbgpic(i)"
                >
                  <v-img
                    cover
                    aspect-ratio="1"
                    max-height="100"
                    :src="getbgpic(i)"
                  >
                    <div primary-title class="titleCentered tshadow">
                      {{ i }}
                    </div>
                  </v-img>
                </v-card>
                <v-card dark elevation="10" v-else>
                  <div class="titleCentered tshadow">
                    {{ i }}
                  </div>
                </v-card>
                <!-- <h1 class="text-center text-h5 font-weight-black pt-3 pb-3">
                  {{ getbgpic(i) }}
                  
                </h1> -->
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row fill-height align="center" justify="start">
                  <v-col v-for="(child, index) in menu[i]" :key="index" cols="6">
                    <v-card>
                      <v-list-item @click="add2cart(child)" v-if="child.picture && settings.whitPhoto">
                        <v-list-item-avatar rounded size="80">
                          <v-img cover :src="
                            getImagePhoto(
                              child.account,
                              child.store,
                              child.picture
                            )
                          " />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-black text-uppercase" v-text="child.product_name" />
                          <v-list-item-subtitle v-html="child.description" />
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text>
                            <slot v-if="child.discount === 0">
                              {{ parseFloat(child.price).toLocaleString() }}
                            </slot>
                            <slot v-else>
                              <small class="text-decoration-line-through">{{
                                  "$ " + parseFloat(child.price).toLocaleString()
                              }}</small><br />
                              {{
                                  "$ " +
                                  (
                                    parseFloat(child.price) *
                                    ((100 - child.discount) / 100)
                                  ).toLocaleString()
                              }}
                            </slot>
                          </v-list-item-action-text>
                          <v-spacer></v-spacer>
                          <v-btn icon x-small>
                            <v-icon>mdi-cart-variant</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item three-line v-else @click="add2cart(child)">
                        <v-list-item-content>
                          <v-list-item-title class="text-uppercase">
                            <b>{{ child.product_name }}</b>
                          </v-list-item-title>
                          <v-list-item-subtitle class="text--primary" v-html="child.description"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text>{{
                              parseFloat(child.price).toLocaleString()
                          }}</v-list-item-action-text>
                          <v-spacer></v-spacer>
                          <v-btn icon x-small>
                            <v-icon>mdi-cart-variant</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <v-dialog v-model="dialog" persistent max-width="400px" :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-card-title class="pa-0">
          <v-app-bar flat>
            {{ table_selected }}
            <v-spacer></v-spacer>
            <v-btn x-small color="info" fab @click="dialog = !dialog">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-app-bar>
        </v-card-title>

        <v-card-title>
          {{ itemSelected.name }}
        </v-card-title>
        <v-card-text>
          <span v-html="itemSelected.description"></span>
          <v-textarea v-model="itemSelected.note" auto-grow label="Note" rows="2" />
          <template>
            <v-row>
              <v-col cols="12" v-for="(value, key) in itemSelected.product_options" :key="key" justify="center">
                <h3 v-if="selecttype(itemSelected.product_options[key])">
                  Seleccione la {{ key }} que desee
                </h3>
                <h3 v-else>Seleccione los {{ key }} que desee</h3>
                <v-data-table :headers="header_selected" hide-default-header hide-default-footer :items-per-page="-1"
                  mobile-breakpoint="0" :items="itemSelected.product_options[key]" item-key="product_name"
                  :single-select="
                    selectedLst(itemSelected.product_options[key])
                  " class="elevation-1" dense>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-checkbox hide-details v-model="item.selected" @click="itm_selected(item)" class="no_label" />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-card-actions>
          <template>
            <v-select v-model="itemSelected.seat" :items="seat_lst" label="Silla" class="select_w pr-3" hide-details
              single-line prepend-inner-icon="mdi-seat-passenger" outlined rounded></v-select>
          </template>
          <v-spacer></v-spacer>
          <template>
            <v-text-field v-model="itemSelected.qty" class="center pr-3" hide-details filled rounded single-line
              prepend-inner-icon="mdi-minus" append-icon="mdi-plus" @click:prepend-inner="restQty"
              @click:append="addQty" />
          </template>
          <v-spacer></v-spacer>
          <v-btn v-if="edit" large dark :color="success_dark" type="button" @click="updateCart()" rounded>
            <v-icon> mdi-cart-variant </v-icon>
          </v-btn>
          <v-btn v-else large dark :color="success_dark" type="button" @click="add2cart(itemSelected)" rounded>
            <v-icon dark> mdi-cart-variant </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import jsPDF from "jspdf";
import { webserver, isValidJSONString } from "../../services/webserver.js";
import createParty from "../../utils/createStore.js";
import createWebset from "../../utils/createSetting.js";
import {
  indexed_init,
  indexedDB_putdata,
  indexed_getdata,
} from "../../services/indexedDb.js";

//indexedDB_putdata,

import * as db from "../../firebaseRT";
import { getDatabase, ref, set } from "firebase/database";
const database = getDatabase();

export default {
  beforeMount() {
    this.store = this.$store.getters.company;
    if (this.store.setting) {
      this.settings = JSON.parse(this.store.setting);
    }
    indexed_init();
    this.initBills();
  },
  data() {
    return {
      success_dark: "#128C7E",
      success_color: "#B2DFDB",
      warning_color: "#FFE0B2",
      secondary_color: "#80CBC4",
      area_selected: "Barra",
      areas: ["Barra", "Terraza", "Sala", "Patio"],
      bill_selected: null,
      cart: [],
      categories: [],
      container: null,
      colors: [
        "#BBDEFB",
        "#BBDEFB",
        "#E6EE9C",
        "#FFCC80",
        "#76FF03",
        "#388E3C",
        "#D1C4E9",
      ],
      edit: false,
      header: [
        {
          text: "Cant.",
          align: "center",
          value: "qty",
          width: 0,
        },
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "price",
        },
      ],
      header_selected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
          width: 20,
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      headerSelected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
          width: 20,
        },
      ],
      itemsby: null,
      dialog_table: false,
      dialog_products: true,
      dialog_menu: false,
      dialog: false,
      items: [],
      itemSelected: {},
      loading_status: false,
      menu: [],
      menus: null,
      menu_selected: null,
      menuName: null,
      mode_payment: null,
      mode_of_payment: [
        { code: "Cash", name: "Cash" },
        { code: "Debit/Credit", name: "Debit/Credit" },
      ],
      num: 0,
      order: {
        pos: createParty(),
        party: createParty(),
        total: 0,
        items: [],
      },
      options_selected: null,
      optSelected: [],
      optionsLst: [],
      payment_type: [
        { code: "A", name: "All on one" },
        { code: "B", name: "Split equal by seat" },
        { code: "C", name: "Split by seating" },
      ],
      pdfview: false,
      pdfFile: "https://416-to.ca/test.pdf",
      payments: false,
      pType: null,
      seat_lst: [1, 2, 3, 4, 5, 6],
      settings: createWebset(),
      storeBills: {},
      store: createParty(),
      table_selected: "Barra-1",
      table_dialog: false,
      tab: null,
      tbls: 10,
      totals: { qty: 0, total: 0, tax: 0, complete: false },
    };
  },
  mounted() {
    this.get_catalog();
    indexed_getdata("consecutives", null, function () {
      //console.log(data);
    });
    indexed_getdata("invoices", null, function () {
      //console.log(data);
    });
    db;
  },
  methods: {
    getbgpic(ctgry) {
      var phtslst = [];
      if (ctgry) {
        phtslst = this.menu[ctgry];
      } else {
        phtslst = this.menu;
      }
      if (phtslst.length > 0) {
        phtslst = phtslst.filter(function (el) {
          return el.picture !== "";
        });
      }

      if (phtslst.length > 0) {
        var rdm = phtslst[(phtslst.length * Math.random()) | 0];

        if (rdm.picture) {
          return this.getImagePhoto(rdm.account, rdm.store, rdm.picture);
        } else {
          if (window.store.picture) {
            return this.getImagePhoto(
              window.store.account,
              window.store.store,
              window.store.picture
            );
          }
          return "";
        }
      } else {
        return "";
      }
    },
    addQty() {
      this.itemSelected.qty = this.itemSelected.qty + 1;
    },
    add2cart(e) {
      //console.log(e);
      this.edit = false;
      if (this.table_selected) {
        e.options_selected = JSON.stringify(this.options_selected);
        if (!e.seat) {
          e.seat = 1;
        }
        if (!e.qty) {
          e.qty = 1;
        }
        const newItem = { ...e };
        this.storeBills[this.table_selected].bill.push(newItem);
        this.tab = 1;
      } else {
        this.tab = 0;
      }
      indexedDB_putdata("bills", "tbl", [this.storeBills[this.table_selected]]);
      this.cart = this.storeBills[this.table_selected].bill;
      this.dialog = false;
    },
    changeMenu(e) {
      this.menu_selected = this.itemsby[e];
      this.menuName = e;
    },
    changeArea(e) {
      this.area_selected = e;
      this.tab = "1";
    },
    changeTable(e) {
      this.table_selected = e;
      this.cart = this.storeBills[e].bill;
      this.tab = 1;
      if (this.storeBills[this.table_selected].total === 0) {
        this.dialog_menu = true;
      }
    },
    createPDF() {

      var doc = new jsPDF();

      doc.text("Hello World", 10, 10);

      const blob = doc.output("blob");
      const objectUrl = URL.createObjectURL(blob);
      ////console.log(objectUrl);
      this.pdfFile = objectUrl;
      //doc.autoPrint({variant: 'non-conform'});
      ////console.log(doc.output("datauri"));
      //doc.save(pdfName + ".pdf");

      //This is a key for printing
      //this.pdfFile = doc.output("datauristring");
      ////console.log(this.pdfFile);
      this.pdfview = true;
      // doc.autoPrint();
      // doc.output("datauri");
      //doc.autoPrint();
      // doc.output("blob");

      // this.pdfview=true;
      //doc.viewerPreferences({ FitWindow: true }, true);
      // doc.save("viewerPreferences.pdf");

      // // Example printing 10 copies, using cropbox, and hiding UI.
      // doc.viewerPreferences({
      //   HideWindowUI: true,
      //   PrintArea: "CropBox",
      //   NumCopies: 10,
      // });
    },
    deleteItem(e) {
      var index = this.cart.indexOf(e);
      if (index !== -1) {
        this.cart.splice(index, 1);
      }
      localStorage.cart = Buffer.from(JSON.stringify(this.cart)).toString(
        "base64"
      );
      indexedDB_putdata("bills", "tbl", [this.storeBills[this.table_selected]]);
      this.updateTotals();
    },
    editItem(e, edit) {
      if (e.active) {
        this.edit = edit;
        this.$set(this, "itemSelected", e);
        this.dialog = true;
      }
    },
    getImagePhoto: function (account, store, src) {
      var url =
        "https://smartchef.pro/photos/" + account + "/" + store + "/" + src;

      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    get_catalog() {
      this.loading_status = true;
      var qry = {
        account: this.store.account,
        table: "catalogue",
        filters: [{ field: "store", value: this.store.code, operator: "=" }],
      };
      console.log(qry);
      webserver("get_table", qry, (data) => {
        console.log(data);
        data.forEach((rg) => {
          rg.qty = 1;
          rg.active = true;
          rg.note = "";
          if (rg.price) rg.price = parseFloat(rg.price);
          if (rg.tax) rg.tax = parseFloat(rg.tax);
          if (rg.discount) rg.discount = parseFloat(rg.discount);
          if (rg.cost_uom) rg.cost_uom = parseFloat(rg.cost_uom);
          if (rg.product_options) {
            rg.product_options = JSON.parse(rg.product_options);
          } else rg.product_options = null;
          if (rg.value_options) {
            rg.value_options = parseFloat(rg.value_options);
          } else rg.value_options = 0;
        });
        var ctgry = data.reduce(function (r, a) {
          r[a.category] = r[a.category] || [];
          r[a.category].push(a);
          return r;
        }, Object.create(null));

        this.menu = ctgry;
        this.categories = Object.keys(ctgry).sort();

        ////console.log(ctgry);
        this.items = data;
        this.loading_status = false;
      });
    },
    initBills() {
      this.area_selected = "Barra";
      this.table_selected = "Barra-1";
      this.areas.forEach((value) => {
        for (var i = 1; i < this.tbls + 1; i++) {
          var tbl = value + "-" + i;
          this.storeBills[tbl] = {};
          this.storeBills[tbl].tbl = tbl;
          this.storeBills[tbl].seat = 0;
          this.storeBills[tbl].bill = [];
          this.storeBills[tbl].total = 0;
          //indexedDB_putdata("bills", "tbl", [this.storeBills[tbl]]);
        }
      });
      indexed_getdata("bills", null, (data) => {
        data.forEach((value) => {
          this.storeBills[value.tbl] = value;
        });
      });
      this.updateCloud(this.storeBills);
      this.cart = this.storeBills[this.table_selected].bill;
    },
    menuSize() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return "100%";
      } else {
        return "50%";
      }
    },
    oSelected(e) {
      return JSON.parse(e);
    },
    itm_selected() {
      this.updateTotals();
    },
    restQty() {
      this.itemSelected.qty = this.itemSelected.qty - 1;
    },
    saveDoc() {
      var itms = this.storeBills[this.table_selected].bill.filter(function (
        el
      ) {
        return el.active !== false;
      });

      itms.forEach((itm) => {
        itm.product_options = JSON.stringify(itm.product_options);
      });

      if (itms.length > 0) {
        this.order.pos = this.store;
        this.order.account = this.order.pos.account;
        this.order.doc_type = "PS";
        this.order.store = this.order.pos.code;
        this.order.userId = this.$store.getters.profile.email;
        this.order.agent = this.$store.getters.profile.name;
        this.order.items = itms;
        this.order.total = this.storeBills[this.table_selected].total;
        this.order.party.name = this.table_selected;
        this.order.party.address = "";
        this.order.party.email = "";
        this.order.party.mobile = "";
        this.order.party.country = this.order.pos.country;
        this.order.party.province = this.order.pos.province;
        this.order.party.city = this.order.pos.city;

        console.log(this.order);

        webserver("put_doc", this.order, (data) => {
          console.log(data);
          this.send_order(data.header.store, data.header.doc_code);
          this.storeBills[this.table_selected].bill.forEach((itm) => {
            itm.active = false;
            //itm.product_options = JSON.parse(itm.product_options);
          });

          this.cart.forEach((rg) => {
            if (isValidJSONString(rg.product_options)) {
              rg.product_options = JSON.parse(rg.product_options);
            }
          });

          indexedDB_putdata("bills", "tbl", [
            this.storeBills[this.table_selected],
          ]);
          this.updateTotals();
          this.loading_status = false;
        });
      }
    },
    selecttype(e) {
      return e[0].unique;
    },
    send_order(store, data) {
      set(ref(database, "orders/" + store), data).then(console.log("Enviado"));
    },
    selectedLst(e) {
      return e[0].unique;
    },
    updateCart() {
      this.edit = false;
      // this.itemSelected.options_selected = JSON.stringify(
      //   this.options_selected
      // );
      indexedDB_putdata("bills", "tbl", [this.storeBills[this.table_selected]]);
      this.updateTotals();
      this.dialog = false;
    },
    updateTotals() {
      var itms = this.cart;
      var qty = 0;
      var tax = 0;
      var total = 0;
      var value_options = 0;
      var complete = true;
      //console.log(this.cart);
      itms.forEach((itm) => {
        if (itm.active) {
          complete = false;
        }
        if (itm.product_options) {
          //itm.product_options =  JSON.parse(itm.product_options);
          var slt = itm.product_options;
          var tselected = 0;
          Object.keys(slt).forEach((key) => {
            slt[key].forEach((tm) => {
              if (tm.selected) {
                tselected += parseFloat(tm.price);
              }
            });
          });
          itm.value_options = tselected;
        }
        qty += parseFloat(itm.qty);
        total +=
          parseFloat(itm.qty) *
          (parseFloat(itm.price) + parseFloat(itm.value_options));
        value_options += parseFloat(itm.value_options);
        tax +=
          (parseFloat(itm.qty) *
            (parseFloat(itm.price) + parseFloat(itm.value_options)) *
            parseFloat(itm.tax)) /
          100;
      });
      var group = itms.reduce((r, a) => {
        r[a.seat] = [...(r[a.seat] || []), a];
        return r;
      }, {});

      this.totals.qty = qty;
      this.totals.tax = tax;
      this.totals.total = total;
      this.totals.complete = complete;
      //console.log(this.totals);
      if (this.table_selected) {
        this.storeBills[this.table_selected].seat = Object.keys(group).length;
        this.storeBills[this.table_selected].total =
          total + value_options + tax;
      }
    },
    updateCloud(data) {
      var itms = {
        account: this.store.account,
        store: this.store.code,
        bills: JSON.stringify(data),
      };

      var qry = {
        account: this.store.account,
        table: "bills",
        data: [itms],
      };
      //console.log(qry);
      webserver("put_table", qry, () => { });
    },
  },
  updated() {
    //this.scroll();
  },
  watch: {
    // itemSelected: function (e) {
    //   this.options_selected = [];
    //   if (e.options_selected) {
    //     this.options_selected = JSON.parse(e.options_selected);
    //   } else {
    //     if (e.product_options) {
    //       var opt = JSON.parse(e.product_options);
    //       if (opt.data) {
    //         opt.data.forEach((rg) => {
    //           if (!rg.selected) {
    //             rg.selected = false;
    //           }
    //         });
    //         this.options_selected = opt.data.reduce(function (r, a) {
    //           r[a.category] = r[a.category] || [];
    //           r[a.category].push(a);
    //           return r;
    //         }, Object.create(null));
    //       }
    //     }
    //   }
    // },
    cart: function () {
      this.updateTotals();
    },
  },
};
</script>

<style>
.barsticky {
  top: 64px;
  position: fixed;
  z-index: 10;
  width: 100%;
}

.b-fixed {
  bottom: 0px;
  left: 0px;
  position: fixed;
  z-index: 10;
}

.no_label {
  width: 0px;
}
.tshadow {
  text-shadow: black -1px 2px 6px;
  color: white;
  font-size: 2em;
}
.titleCentered {
  font-size: 1.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
