export const indexed_init = () => {
    //prefixes of implementation that we want to test

    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    //prefixes of window.IDB objects
    window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction;
    window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange
    if (!indexedDB) {
        console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.")
    }
    const dbName = "pos";
    var request = indexedDB.open(dbName, 1);
    request.onerror = function (event) {
        console.log("error: " + event);
    };

    request.onupgradeneeded = function (event) {
        var db = event.target.result;
        var clients = db.createObjectStore("clients", { keyPath: "id_client" });
        var invoices = db.createObjectStore("invoices", { keyPath: "docId" });
        var consecutives = db.createObjectStore("consecutives", { keyPath: "doc_type" });
        var bills = db.createObjectStore("bills", { keyPath: "tbl" });
        clients.createIndex("id_client", "id_client", { unique: true });
        invoices.createIndex("docId", "docId", { unique: true });
        bills.createIndex("tbl", "tbl", { unique: true });
        //consecutives.createIndex("doc_type", "doc_type", { unique: true });
        const initConse = [
            { doc_type: "OL-CLI", counter: 0 },
            { doc_type: "OL-PRO", counter: 0 },
            { doc_type: "OL-FRM", counter: 0 }
        ];

        for (var i in initConse) {
            consecutives.add(initConse[i]);
        }


    }
}

export const indexed_getdata = (tbl, rg, callback) => {
    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    var open = indexedDB.open("pos");
    open.onsuccess = function () {
        var db = open.result;
        var tx = db.transaction(tbl, "readonly");
        var store = tx.objectStore(tbl);
        var request = store.getAll(rg);
        request.onerror = function (event) {
            console.log(event);
        };
        request.onsuccess = function () {
            callback(request.result);
        };
    }
}

export const indexedDB_putdata = (tbl, idx, data) => {
    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    var open = indexedDB.open("pos");
    open.onsuccess = function () {
        var db = open.result;
        var tx = db.transaction(tbl, "readwrite");
        var store = tx.objectStore(tbl);
        var index = store.index(idx);
        if (data.length > 0) {
            for (var z in data) {
                store.put(data[z]);
            }
        }
        tx.oncomplete = function () {
            console.log('Index' + index + 'Registers :' + data.length);
            db.close();
        };
    };

}