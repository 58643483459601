import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

// Set the configuration for your app
// TODO: Replace with your project's config object
const firebaseConfig = {
    apiKey: "AIzaSyC_aeBEppq_z9pxVQ4Z6-vkMy_OZuQNPJU",
    authDomain: "qr4r-restaurant.firebaseapp.com",
    databaseURL: "https://qr4r-restaurant-default-rtdb.firebaseio.com",
    storageBucket: "qr4r-restaurant.appspot.com",
};

const app = initializeApp(firebaseConfig);

// Get a reference to the database service
export const database = getDatabase(app);
